import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Video from "components/Video/Video.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/cases/horus/horus_01.jpg";
import image2 from "assets/img/cases/horus/horus_02.jpg";
import image3 from "assets/img/cases/horus/horus_03.jpg";
import image4 from "assets/img/cases/horus/horus_04.jpg";
import image5 from "assets/img/cases/horus/horus_05.jpg";
import image6 from "assets/img/cases/horus/horus_06.jpg";
import image7 from "assets/img/cases/horus/horus_07.jpg";

class LiveSYNCFortumSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Documentation and Visual Reporting</h2>
              <h4>Improving work efficiency in power plants</h4>
              <br/>
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Video",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <Video
                        videoSrcURL="https://player.vimeo.com/video/328191256?dnt=1&app_id=122963\"
                        videoTitle="Official Music Video on YouTube"
                        aspectRatio="56.25%"
                      />
                    </div>
                  )
                },                
                {
                  tabName: "Photos",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image5} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image6} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image7} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          The assembly process of a mobile phone network base station product involves 
                          multiple phases. After completing one assembly phase, the PCBs are stored inside
                          module racks into a specific buffer area where they wait for the next phase.
                          Optimizing the throughput of the factory requires real-time knowledge of 
                          the amount of modules in each phase. For example, to prevent bottle-necks in
                          production, a buffer area should never be completely full nor empty.
                        </p>
                        <p>
                          <b>
                          To learn how video analytics can be used in your company, contact us and book a 
                          meeting. Our offering includes on-site consultation for analysing potential
                          applications in co-operation with the customer.
                          </b>
                        </p>
                      </div>
                    </GridContainer>
                  )
                },
                {
                  tabName: "Links",
                  tabIcon: undefined,
                  tabContent: (
                    <p className={classes.textCenter}>
                      I think that’s a responsibility that I have, to push
                      possibilities, to show people, this is the level that
                      things could be at. So when you get something that has
                      the name Kanye West on it, it’s supposed to be pushing
                      the furthest possibilities. I will be the leader of a
                      company that ends up being worth billions of dollars,
                      because I got the answers. I understand culture. I am
                      the nucleus.
                    </p>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(LiveSYNCFortumSection);
